import React from 'react'
import { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Button, Form, Input, Select, Modal, Tooltip, Radio,  RadioChangeEvent } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from "react-redux";
import { getUser, logout } from "../../../features/Auth/AuthSlice.jsx";
import { toast } from "react-toastify";
import axios from "axios";
import { baseApiUrl } from "../../../Utils/constants.js";
import { updateWalletBalance, walletBalance } from "../../../features/Wallet/WalletSlice.jsx";
import Spinner from "../../../Component/Spinner.jsx";
import ReactGA from 'react-ga';

const Buy_Airtime = () => {
  const { user } = useSelector(getUser)
  // const { balance } = useSelector(walletBalance)
  // const [utility, setUtility] = useState('')
  const [calculatedAmount, setCalculatedAmount] = useState()
  
  const [commission, setCommission] = useState()
  const [amount, setAmount] = useState()
  const [Loading, setLoading] = useState(false)
  const [disable, setDisable] = useState(false)
  const [type, setType] = useState()
  const errorTokenExpired = 'token expired'
  const errorTokenInvalid = "Token is invalid"

  const buyUtilityApi = 'api/utility/buy-electricity'
  const utilityCommisionApi = 'api/utility-commission/get-commission-by-utility'

  const [form] = Form.useForm();

  const dispatch = useDispatch()




////Buy BuyRingoUtility
const BuyRingoUtility = async (data) => {
  ReactGA.event({
    category: 'Button Click',
    action: 'Clicked on Buy Now',
    label: 'Buy Airtime',
  });
  const headers = {
    'Authorization': `Bearer ${user?.accessToken}`,
    'Content-Type': 'application/json'
  }

  try {
    //console.log(data, `${baseApiUrl}/${buyUtilityApi}`)
    const res = await axios.post(`${baseApiUrl}/${buyUtilityApi}`, data, { headers })
    console.log(res)
    const { message,  status } = res.data
    if (message === "Successful" && status===200) {
      toast.success(message)
      dispatch(updateWalletBalance(walletBalance))
    } else {
      console.log(res.data)
      toast.error(message)
    }
  } catch (error) {
    if (error.response.data === errorTokenExpired || error.response.data === errorTokenInvalid) {
      toast.error('Error!! Please Login again')
      dispatch(logout())
    } else if (error.response.data) {
     // toast.error(error.response.data.message)
      toast.error('something is ...')
    } else {
      toast.error(error)
    }
  } finally {
    form.resetFields()
    setLoading(false)
    setDisable(false)
  }
}


//Changing the prepaid and postpaid
// const onTypeChange = (e) => {
//   setType(e.target.value)
// }

///submission for airtime
  const onFinish = async (e) => {
    setDisable(true)
    setLoading(true)
   if ( e.meterNo.length === 11) {
   
      const FormData = { disco: e.disco, meterNo: e.meterNo, amount:Number(e.amount), phonenumber:e.phonenumber, email:e.email, type:e.type, discountedAmount:calculatedAmount}
      console.log(FormData)
     BuyRingoUtility(FormData)
    } else if (e.meterNo.length !== 11) {
      toast.info('meter Number is Incomplete')
      form.resetFields()
      setLoading(false)
      setDisable(false)
    } else {
      toast.info('meter  Number Format is incorrect')
      form.resetFields()
      setLoading(false)
      setDisable(false)
    }
  }
  
  const fetchCommissionForUser = async () => {
    try {
      setLoading(true)
      
      let networkparams = 'electricity'
      const headers = {
        'Authorization': `Bearer ${user?.accessToken}`,
        'Content-Type': 'application/json'
      }
      const response = await axios.get(`${baseApiUrl}/${utilityCommisionApi}/${networkparams}`, { headers })
      setCommission(response.data.commission)
      console.log(response.data.commission)
    } catch (error) {
      if (error.response.data === errorTokenExpired || error.response.data === errorTokenInvalid) {
        toast.error('Error!! Please Login again')
        dispatch(logout())
      } else if (error.response.data) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (amount) {
     fetchCommissionForUser()
     
      form.resetFields(["utility"])
      form.resetFields(["msisdn"])
      setCalculatedAmount()
    }
  }, [amount])

  useEffect(() => {
    let amountToDeduct = commission / 100 * amount
    setCalculatedAmount(amount - amountToDeduct)
    
  }, [commission, amount])



  return (
    <div>
      <Helmet defer={false}>
        <title>Fliprecharge || Buy Electricity</title>
      </Helmet>
      {Loading && <Spinner />}
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{ height: "70vh" }}></div>
      {/* Page content */}
      <Container className="" style={{ marginTop: "-22rem" }} fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Buy Electricity</h3>
              </CardHeader>
              <CardBody>
                <Form form={form} className='w-100 bg-white p-4 rounded-xl' onFinish={onFinish}>
                <Form.Item className="w-100" name="type" rules={[{ required: 'true', message: "Please select the type" }]}>
                <Radio.Group
                      value={type}
                      
                      onChange={(e) => setType(e.target.value)}
                    
                      options={[
                        { value: 'prepaid', label: "Prepaid" },
                        { value: 'postpaid', label: "Postpaid" },
                       
                      ]}
                    />
                    </Form.Item>
                  <label htmlFor="">Service</label>
                  <Form.Item className="w-100" name="disco" rules={[{ required: 'true', message: "Please select a network" }]}>
                    <Select  placeholder="Select a network">
                    <Select.Option value='IKDEC'>Ikeja Electricity -IKDEC </Select.Option>
                    <Select.Option value='KEDCO'>KANO Electricity - KEDCO</Select.Option>
                    <Select.Option value='PHED'>Port Hacourt Electricity-PHED</Select.Option>
                    <Select.Option value='JED'>Jos Electricity - JED</Select.Option>
                    <Select.Option value='EKEDS'>Eko Electricity - EKEDS</Select.Option>
                    <Select.Option value='AEDC'>Abuja Electricity - AEDC </Select.Option>
                    <Select.Option value='KAEDCO'>Kaduna Electricity - KAEDCO</Select.Option>
                    <Select.Option value='IBDEC'>Ibadan Electricity - IBDEC</Select.Option>
                    <Select.Option value='EEDC'>Enugu Electricity - EEDC</Select.Option>
                    <Select.Option value='BEDC'>Benin Electricity - BEDC</Select.Option>
                    </Select>
                  </Form.Item>

                  <label htmlFor="">Meter Number</label>
                  <Form.Item className="w-100" name="meterNo" rules={[{ required: 'true', message: "Please input your Meter Number" }]}>
                    <Input maxLength={11}
                      suffix={
                        <Tooltip title="Eg: 0712345678">
                          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                  <label htmlFor="">Phone</label>
                  <Form.Item className="w-100" name="phonenumber" rules={[{ required: 'true', message: "Please input your Mobile Number" }]}>
                    <Input maxLength={11}
                      suffix={
                        <Tooltip title="Eg: 0812345678">
                          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }
                    />
                  </Form.Item>

                  <label htmlFor="">Email</label>
                  <Form.Item className="w-100" name="email" rules={[{ required: 'true', message: "Please input your Mobile Number" }]}>
                    <Input 
                      suffix={
                        <Tooltip title="Eg: john@gmail.com">
                          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }
                      type='email'
                    />
                  </Form.Item>
                  <label htmlFor="">Utility Amount</label>
                  <Form.Item className="w-100" name="amount" rules={[{ required: 'true', message: "Please input airtime amount" }]}>
                    <Input type='number' value={amount} onChange={(e) => setAmount((Number(e.target.value)))} placeholder=' Amount' />
                  </Form.Item>
              <label htmlFor="">Amount</label>
              <Form.Item className="w-100"  rules={[{ required: 'true', message: "Please input amount" }]}>
                <Input value={calculatedAmount} disabled type='number' />
              </Form.Item>
                  {/* <label htmlFor="">Amount</label>
                  <Form.Item className="w-100" rules={[{ required: 'true', message: "Please input amount" }]}>
                    <Input value={calculatedAmount} disabled type='number' />
                  </Form.Item> */}
                  <Form.Item>
                    <Button className="bg-gradient-info text-white d-flex justify-content-center align-items-center my-3 px-4 py-3 rounded-lg" htmlType="submit" disabled={disable}>Buy now</Button>
                  </Form.Item>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default Buy_Airtime