import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UtilityTransactionService from './UtilityTransactionService'

const initialState = {
    utilityTransaction: [],
    isErrorUtilityTranxn: false,
    isSuccessUtilityTranxn: false,
    isLoadingUtilityTranxn: false,
    messageUtilityTranxn: "",
    utilityTransactionUtil: "",
}

//get all data transactions
export const getAllUtilityTransaction = createAsyncThunk('utilitytransaction/user', async(userId, thunkAPI)=> {
    try {
       const accesstoken = thunkAPI.getState().auth.user.accessToken
       return await UtilityTransactionService.findAllUtilityTransaction(accesstoken, userId)
    } catch (error) {
        const message = error.response.data
        return thunkAPI.rejectWithValue(message)
    }
})

const UtilityTransactionSlice = createSlice({
  name: "utilityTransaction",
  initialState,
  reducers: {
    resetUtilityTransactions: (state) => {
        state.isLoadingUtilityTranxn = false
        state.isErrorUtilityTranxn= false
        state.isSuccessUtilityTranxn= false
        state.messageUtilityTranxn=""
    },
    utilityTransactionByDates: (state, action) => {
        state.utilityTransaction = action.payload
    },
    setUtilityTransaction: (state, action) => {
        state.utilityTransactionUtil = action.payload
    }, 
  },
  extraReducers: (builder) => {
    builder
    .addCase(getAllUtilityTransaction.pending, (state)=> {
        state.isLoadingUtilityTranxn = true
    })
    .addCase(getAllUtilityTransaction.fulfilled, (state, action)=> {
        state.isLoadingUtilityTranxn = false
        state.isSuccessUtilityTranxn = true
        state.utilityTransaction = action.payload 
    })
    .addCase(getAllUtilityTransaction.rejected, (state, action)=> {
        state.isLoadingUtilityTranxn = false
        state.isErrorUtilityTranxn = true
        state.message = action.payload
    })
}
});

export const utilityTransactions = state => state.utilityTransaction

export const {resetUtilityTransactions, utilityTransactionByDates, setUtilityTransaction} = UtilityTransactionSlice.actions

export default UtilityTransactionSlice.reducer