import React, { useEffect, useRef, useState } from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
  } from "reactstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from 'primereact/inputtext'
import { Button, Form, Input, Select, Modal, Tooltip } from 'antd'
import { FilterMatchMode } from 'primereact/api'
import { useDispatch, useSelector } from 'react-redux';
import { getUser, logout } from '../../../features/Auth/AuthSlice';
import Spinner from '../../../Component/Spinner';
import axios from 'axios';
import { baseApiUrl } from '../../../Utils/constants';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

const UtilityCommission = () => {
    const [isAddPlanOpen, setIsAddPlanOpen] = useState(false)
    const [isDeletePlanOpen, setIsDeletePlanOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [editData, setEditData] = useState([])
    const [deleteData, setDeleteData] = useState([])
    const [utilityCommission, setUtilityCommission] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingData, setIsLoadingData] = useState(false)
    const dispatch = useDispatch()
    const [filter, setFilter] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    })
    const {user} = useSelector(getUser)
    const formRef = useRef(null)

    const fetchData = async() => {
        try {
            setIsLoadingData(true)
            const headers = {
                'Authorization': `Bearer ${user?.accessToken}`,
                'Content-Type': 'application/json'
      
              };
              const response = await axios.get(baseApiUrl + '/api/utility-commission/get-all-commission', {headers})
              setUtilityCommission(response.data)
        } catch (error) {
            if(error.response.data === 'token expired' || error.response.data === 'Token is invalid') {
                toast.error('Error!! Please Login again')
                dispatch(logout())
              } else if(error.response){
                    toast.error(error.response.data)
                } else {
                  toast.error(error)
                }
        } finally {
            setIsLoadingData(false)
        }
    }

    useEffect(()=> {
        fetchData()
    }, [])

    const onFinish = async(formData) => {
        try {
            setIsLoading(true)
            const headers = {
                'Authorization': `Bearer ${user?.accessToken}`,
                'Content-Type': 'application/json'
      
              };
              const response = await axios.post(baseApiUrl + '/api/utility-commission/create-commission', formData, {headers})
              toast.success(response.data)
        } catch (error) {
            if(error.response.data === 'token expired' || error.response.data === 'Token is invalid') {
                toast.error('Error!! Please Login again')
                dispatch(logout())
              } else if(error.response){
                    toast.error(error.response.data)
                } else {
                  toast.error(error)
                }
        } finally {
            formRef.current.resetFields()
            setIsAddPlanOpen(false)
            fetchData()
            setIsLoading(false)
        }
    }

    const onSubmitEdit = async(formData)=> {
        const dataId = editData[0]._id
        try {
            setIsLoading(true)
            const headers = {
                'Authorization': `Bearer ${user?.accessToken}`,
                'Content-Type': 'application/json'
      
              };
            const response = await axios.put(baseApiUrl + '/api/utility-commission/update-commission/' + dataId, formData, {headers})
            toast.success(response.data)
        } catch (error) {
            if(error.response.data === 'token expired' || error.response.data === 'Token is invalid') {
                toast.error('Error!! Please Login again')
                dispatch(logout())
              } else if(error.response){
                    toast.error(error.response.data)
                } else {
                  toast.error(error)
                }          
        } finally {
            formRef.current.resetFields()
            setIsEditModalOpen(false)
            fetchData()
            setIsLoading(false)
        }
    }

    const deleteOkButton = async()=> {
        // const data = deleteData.find((data)=> data._id)
        const dataId = deleteData[0]?._id
        try {
            setIsLoading(true)
            const headers = {
                'Authorization': `Bearer ${user?.accessToken}`,
                'Content-Type': 'application/json'
      
              };
            const response = await axios.delete(baseApiUrl + '/api/utility-commission/delete-commission/' + dataId, {headers})
            toast.success(response.data)
        } catch (error) {
            if(error.response.data === 'token expired' || error.response.data === 'Token is invalid') {
                toast.error('Error!! Please Login again')
                dispatch(logout())
            } else if(error.response){
                    toast.error(error.response.data)
            } else {
                  toast.error(error)
            }
        } finally {
            setIsDeletePlanOpen(false)
            fetchData()
            setIsLoading(false)
        }
    }


    const onEdit =(dataId)=> {
        const dataToEdit = utilityCommission.filter((dataPlan)=> dataId === dataPlan?._id)
        if(dataToEdit){
        setEditData(dataToEdit)
        setIsEditModalOpen(true)
        }
    }

    const onDelete = async(dataId)=> {
        const dataToDelete = utilityCommission.filter((dataPlan)=> dataId === dataPlan?._id)
        if(dataToDelete){
          setDeleteData(dataToDelete)
          setIsDeletePlanOpen(true)
        }
  }

    const editColumnTemplate = (rowData) => {
        return (
          <i className="fas fa-pen-to-square mx-4 text-primary" style={{cursor: "pointer"}} 
          onClick={()=>onEdit(rowData?._id)}/>
        );
      };
      
      const deleteColumnTemplate = (rowData) => {
        return (
          <i className="fa-sharp fa-solid fa-trash text-danger" style={{cursor: "pointer"}}
            onClick={() => onDelete(rowData?._id)}
          />
        );
      };

      const networkColumnTemplate = (data)=> {
        return data?.utility?.toUpperCase()
    }

  return (
    <div>
        <Helmet defer={false}>
                <title>Fliprecharge || Utility Commission</title>
        </Helmet>
            {isLoading && <Spinner />}
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{ height: "70vh" }}></div>
            {/* Delete Modal */}
            <Modal open={isDeletePlanOpen} onOk={deleteOkButton} onCancel={()=>setIsDeletePlanOpen(false)} title='' className="" width={700} centered  >
            {deleteData?.map(data=> 
            <Card key={data?._id} className="shadow mt-4">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Delete Utility Commission</h3>
              </CardHeader>
              <CardBody>
                <p>{'Are you sure you want to delete this ' + data?.utility.toUpperCase() + ' utility commision?'}</p>
              </CardBody>
            </Card>
              )}
            </Modal> 
             {/* Add Airtime Commission Modal  */}
            <Modal open={isAddPlanOpen} footer={null} onCancel={()=> setIsAddPlanOpen(false)} width={700} title='' className="my-3"  centered>
            <Container className="mx-0" style={{marginTop:''}} fluid>
                {/* Table */}
                <Row>
                <div className="col">
                    <Card className="my-4">
                    <CardHeader className="bg-transparent">
                        <h3 className="mb-0">Add Utility Commission</h3>
                    </CardHeader>
                    <CardBody>
                <Form ref={formRef} className='w-100 bg-white p-2 rounded-xl' onFinish={onFinish}>
                <label htmlFor="">Name</label>
                <Form.Item>
                    <Input disabled value={user?.username}/>
                </Form.Item>
                <label htmlFor="">Utility</label>
                <Form.Item name="utility" rules={[{required: 'true', message: "Please input Utility"}]}>
                    <Select>
                    <Select.Option value='electricity'>Electricity</Select.Option>
                    <Select.Option value='gotv'>Gotv</Select.Option>
                    <Select.Option value='dstv'>Dstv</Select.Option>
                    <Select.Option value='startimes'>Startimes</Select.Option>
                    </Select>
                </Form.Item>
                <label htmlFor="">Commission( % )</label>
                <Form.Item name="commission" rules={[{required: 'true', message: "Please input percentage"}]}>
                    <Input type="number" placeholder='eg: 1' />
                </Form.Item>
                <Form.Item>
                <Button className="bg-gradient-info text-white" htmlType="submit">Submit</Button>
                </Form.Item>
                </Form>
                    </CardBody>
                    </Card>
                </div>
                </Row>
            </Container>
            </Modal>
            {/* Add Commission Button */}
            <div className="mx-auto px-md-4 d-flex justify-content-between flex-wrap" style={{width: "94%", marginTop: "-18rem"}}>
            <Container className="mx-0 alert alert-success d-flex justify-content-center align-items-center col-12 col-md-4" onClick={()=>setIsAddPlanOpen(true)} style={{ width: "18rem", cursor: "pointer"}}  fluid><i className="fas fa-solid fa-plus text-white mr-2"></i>Add a new Commission Plan</Container>
            </div>
            {/* Edit Commission */}
            <Modal open={isEditModalOpen} footer={null} onCancel={()=> setIsEditModalOpen(false)} width={700} title='' className="my-3"  centered>
           {editData?.map((data)=> 
            <Container className="mx-0" style={{marginTop:''}} fluid>
            {/* Table */}
            <Row>
            <div className="col">
                <Card className="my-4">
                <CardHeader className="bg-transparent">
                    <h3 className="mb-0">Edit Utility Commission</h3>
                </CardHeader>
                <CardBody>
            <Form ref={formRef} className='w-100 bg-white p-2 rounded-xl' onFinish={onSubmitEdit}>
            <label htmlFor="">Name</label>
            <Form.Item>
                <Input disabled value={user?.username}/>
            </Form.Item>
            <label htmlFor="">Utility</label>
            <Form.Item name="utility" initialValue={data?.utility} rules={[{required: 'true', message: "Please input Network"}]}>
                <Select disabled>
                <Select.Option value='electricity'>Electricity</Select.Option>
                    <Select.Option value='gotv'>Gotv</Select.Option>
                    <Select.Option value='dstv'>Dstv</Select.Option>
                    <Select.Option value='startimes'>Startimes</Select.Option>
                </Select>
            </Form.Item>
            <label htmlFor="">Commission(%)</label>
            <Form.Item name="commission" initialValue={data?.commission} rules={[{required: 'true', message: "Please input Amount"}]}>
                <Input type="number" placeholder='eg: 1' />
            </Form.Item>
            <Form.Item>
            <Button className="bg-gradient-info text-white" htmlType="submit">Submit</Button>
            </Form.Item>
            </Form>
                </CardBody>
                </Card>
            </div>
            </Row>
        </Container>
           )}
            </Modal>
            {/* Page content */}
            <Container className="" fluid>
                {/* Table */}
                <Card className="shadow px-3 pb-4">
                    <CardHeader className="bg-transparent">
                        <h3 className="mb-0">Utility Commission</h3>
                    </CardHeader>
                    {isLoadingData ? <div className='alert alert-light mt-2'>......Loading</div> : <div><InputText placeholder='search' className='searchdrop p-2 border w-25 my-4' onInput={(e) => setFilter({ global: { value: e.target.value, matchMode: FilterMatchMode.CONTAINS }, })} />
                        <DataTable value={utilityCommission} filters={filter} responsiveLayout='scroll' showGridlines paginator rows={5} rowsPerPageOptions={[5, 10, 20, 40]}>
                            <Column field='' header='Utility' body={networkColumnTemplate} style={{ width: '50%' }}></Column>
                            <Column field='commission' header='Commission(%)' style={{ width: '20%' }}></Column>
                            <Column field='' header='' body={editColumnTemplate} style={{ width: '10%' }}></Column>
                            <Column field='' header='' body={deleteColumnTemplate} style={{ width: '10%' }}></Column>
                        </DataTable></div>}
                </Card>
            </Container>
    </div>
  )
}

export default UtilityCommission