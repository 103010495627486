/*!

=========================================================
* Argon Dashboard React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useEffect, useState } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import Header from "../../components/Headers/Header.js";
import { Button, Form, Input, Select, Modal, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet-async'

import { toast } from "react-toastify";


import Spinner from "../../../Component/Spinner.jsx";
import ReactGA from 'react-ga';
import dstvlogo from '../../../Logos/dstv-logo.jpg';
import gotvlogo from '../../../Logos/gotv-logo.jpg';
import starTimeslogo from '../../../Logos/startimes-logo.png';
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";





const Tv_Types = () => {
  const navigate = useNavigate();


  return (
    <>
      <Helmet defer={false}>
        <title>Fliprecharge || Buy Data</title>
      </Helmet>
      
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{ height: "70vh" }}></div>
      {/* Page content */}
      <Container className="" style={{ marginTop: "-22rem" }} fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Tv Subscription</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
              <NavLink to=''>
                  <img src={dstvlogo} alt="Placeholder Image 1"  className="w-50" />
                  </NavLink>
                  </Col>
                  <Col md="4">
                 {/* user/gotv */}
                  <NavLink to=''>
                    <img src={gotvlogo} alt="Placeholder Image 2"   className="w-50"/>
                    </NavLink>
                  </Col>
                  <Col md="4">
                  {/* /user/startimes */}
                  <NavLink to=''>
                  <img src={starTimeslogo} alt="Placeholder Image 2"   className="w-50"/>
                  </NavLink>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Tv_Types;
