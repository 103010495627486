import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authReducer from '../features/Auth/AuthSlice'
import walletReducer from '../features/Wallet/WalletSlice'
import walletHistoryReducer from '../features/Wallet/WalletHistorySlice'
import dataPlanReducer from '../features/DataPlan/DataPlanSlice'
import dataTransactionReducer from '../features/DataTransaction/DataTransactionSlice'
import utilityTransactionReducer from '../features/UtilityTransaction/UtilityTransactionSlice'
import allUserReducer from '../features/Users/UsersSlice'
import userPaymentReducer from '../features/Payments/UserPaymentSlice'
import allPaymentReducer from '../features/Payments/AllPaymentSlice'
import airtimeTransactionReducer from '../features/AirtimeTransaction/AirtimeTransactionSlice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    wallet: walletReducer,
    walletHistory: walletHistoryReducer,
    dataPlan: dataPlanReducer,
    dataTransaction: dataTransactionReducer,
    utilityTransaction: utilityTransactionReducer,
    allUser: allUserReducer,
    userPayment: userPaymentReducer,
    allPayment: allPaymentReducer,
    airtimeTransaction: airtimeTransactionReducer
  },
});
