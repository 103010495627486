import axios from "axios";
import { baseApiUrl } from "../../Utils/constants";

const API = `${baseApiUrl}/api/utility`

const findAllUtilityTransaction = async(accesstoken, userId)=> {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`
        }
    }
    const response = await axios.get(`${API}/get-utility-transactions/${userId}`, config)
    return response.data
}

const UtilityTransactionService = {
    findAllUtilityTransaction
}

export default UtilityTransactionService